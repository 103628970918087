import React from "react";
import { Helmet } from "react-helmet";
// import Apps from './Apps.js';
import classes from './Menu.module.scss';
import dinnerMenu from '../../MileMarkerDinner.pdf';
import lunchMenu from '../../MileMarkerLunch.pdf';
import dinnerImg from '../../assets/images/menuDinner.png';
import lunchImg from '../../assets/images/menuLunch.png';

// const special = (
// <div className={[classes.special, classes.menuImg].join(' ')}>
//   <div className={classes.msInner}>
//     <span className={classes.sm}>Join us for</span>
//     <h4>Lasagna and Wine Wednesdays</h4>
//     <p>Homemade lasagna &amp; garlic bread
//     <br/><span className={classes.dot}>&middot;</span><br/> choice of caesar or house salad
//     <br/><span className={classes.dot}>&middot;</span><br/> 6oz glass of house wine 
//     <br/><span className={classes.dot}>&middot;</span><br/><span className={classes.price}>$16.99</span></p>
//   </div>
// </div>
// );

const daily = () => {
  return (
    <div id="dailyMenu" className={classes.Daily}>
      <Helmet>
        <title>Waterfront Food and Bar Menu at Mile Marker 158 Dockside</title>
        <meta name="description" content="We offer our amazing menu and waterfront view 7 days a week! Come see us at The Wharf in Orange Beach." />
      </Helmet>
      
      <div className={[classes.titleWrap, classes.med3Def].join(' ')}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}><div className={classes.gborder}>
              <div className={classes.inner}>
                <h1>Mile Marker Menus</h1>
                <p className={classes.plain}>Select an image from the options below to view the full menu.</p>
              </div>
            </div></div>
          </div>
        </div>
      </div>
      <div className={[classes.dailyWrap, classes.medDef].join(' ')}>
        <div className={[classes.mdStack, classes.match].join(' ')}>
          <div className={[classes.menuImgE, classes.lunchX, classes.hlfL].join(' ')}>
            <a href={lunchMenu} target="_blank" rel="noopener noreferrer">
              <p className={classes.menuTitle}>
                <span className={classes.line1}>Lunch Menu</span><br/>
                <span className={classes.line2}>Food &amp; Bar</span>
              </p>
              <img src={lunchImg} alt="Mile Marker Lunch Menu" />
            </a>
          </div>

          <div className={[classes.menuImgE, classes.dinnerX, classes.hlfR].join(' ')}>
            <a href={dinnerMenu} target="_blank" rel="noopener noreferrer">
              <p className={classes.menuTitle}>
                <span className={classes.line1}>Dinner Menu</span><br/>
                <span className={classes.line2}>Food &amp; Bar</span>
              </p>
              <img src={dinnerImg} alt="Mile Marker Dinner Menu" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default daily;