import React from "react";
import { Helmet } from "react-helmet";
import Phone from "../../components/Shared/Phone"
const classes = require('./WineDinner.module.scss');

const wineDinner = () => {
  return (
    <div className={classes.WineDinner}>
      <Helmet>
        <title>Waterfront Wine Dinners at Mile Marker 158 Dockside</title>
        <meta name="description" content="Check out the next wine pairing at Mile Marker 158 Dockside, located at The Wharf in Orange Beach, Alabama." />
      </Helmet>

      <div className={classes.medDef}>
        <div className={classes.menuArea}>
          <div className={classes.inside}>
            <div className={classes.titleRow}>
              <div className={classes.inner}>
                <h1>January Wine Dinner</h1>
                <h2>
                  January 9 <br className={classes.br1}/>
                  <span className={classes.sp1}>&nbsp;|&nbsp;</span> 6pm <span className={classes.sp2}>&nbsp;|&nbsp;</span> $65 Per Guest
                </h2>
                <h3>Hosted by United Johnson Brothers
                  <br/>
                  Featuring Unshackled Wines
                </h3>
                <h4>For Reservations: <Phone where="wineDinner" /></h4>
              </div>
            </div>
          </div>

          <div className={[classes.inside, classes.stip].join(' ')}>
            <div className={classes.menuRow}>
              <div className={classes.menuBoxFull}>
                <h5>First Course</h5>
                <h4>FLASH FRIED CALAMARI</h4>
                <p>Calamari Steaks &middot; Lemon Caper Buerre Blanc</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Unshackled Sauvignon Blanc &middot; CA '21</h4>
              </div>
              
              <div className={classes.menuBoxFull}>
                <h5>Second Course </h5>
                <h4>DRUNKEN MUSSELS</h4>
                <p>Mussels &middot; Chorizo &middot; White Beans &middot; White Wine &middot; French Bread</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Unshackled Chardonnay &middot; CA '21</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Third Course</h5>
                <h4>LAMB CHOPS MARSALA</h4>
                <p>Roasted Garlic &middot; Portobello Mushrooms &middot; Sundried Tomato Polenta &middot; Asparagus &middot; Caramelized Red Onion</p>
                <p className={classes.pair}>Paired With</p>
                <h4>Unshackled Cabernet &middot; CA '22</h4>
              </div>

              <div className={classes.menuBoxFull}>
                <h5>Fourth Course</h5>
                <h4>BLUEBERRY &amp; AMARETTO PANNA COTTA</h4>
                <p className={classes.pair}>Paired With</p>
                <h4>Unshackled Sparkling White &middot; CA '18</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default wineDinner;